<template>
    <div>
        <header-portrate v-if="!is_mobile" />
        <exports-print ref="exportbar" v-if="!is_mobile" />
        <div v-if="is_mobile" style="padding:5px;background:#d7b100">
            <div class="text-center p-2" style="background:red;color:#fff;margin:auto 50px;" @click="$router.push({path: '/logout'})">
                {{ lang.logout }}
            </div>
        </div>
        <div>
            <TabsComp :tabs="tabs" v-if="!is_mobile" />
            <v-row class="mainrow m-2" :style="`direction:`+lang.dir+`;margin-top:-20px !important;`">
                <v-col cols="12" md="12">
                    <div class="showDash">
                        <div class="dashContainer" v-for="(item,index) in statuses" :key="index">
                            <div class="dashTitle">
                                {{ item[`name_${lang.langname}`] }}
                            </div>
                            <div class="containerText">
                                <div class="containerRow">
                                    <div class="rowTitle">{{ lang.customer_number }}</div>
                                    <div class="rowText" style="border-bottom:1px solid #000;"> {{ item.counter }}</div>
                                </div>
                                <div class="containerRow">
                                    <div class="rowTitle">{{ lang.total_income }}</div>
                                    <div class="rowText">{{ item.total }}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </v-col>
            </v-row>
        </div>

        
        <Footer />
    </div>
</template>

<script>
import HeaderPortrate from '@/components/Header-Portrate.vue'
import ExportsPrint from '@/components/exports-print.vue'
import Footer from '@/components/Footer.vue'
import TabsComp from '@/components/tabsComp.vue'
import axios from 'axios'


export default{
    components: {
        HeaderPortrate,
        Footer,
        ExportsPrint
        ,TabsComp
    },
    data() {
        return {
            cStatus: '',
            sd: {
                day: 1,
                month: (new Date()).getMonth() + 1,
                year: (new Date()).getFullYear() - 1,
            },
            ed: {
                day: (new Date()).getDate(),
                month: (new Date()).getMonth() + 1,
                year: (new Date()).getFullYear(),
            },
            statuses: [],

            staticOption: [],
            // date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),

        }
    },
    computed: {
        lang: function(){
            if(this.$cookies.get(this.$store.state.LangCooki) != null){
                if(this.$cookies.get(this.$store.state.LangCooki)  == 'en'){
                return this.$store.state.lang.en;
                }else{
                return this.$store.state.lang.ar;
                }
            }
            else if(this.$store.state.deflang == 'en'){
                return this.$store.state.lang.en;
            }else{
                return this.$store.state.lang.ar;
            }
        },

        sdate: function() {
          return (new Date(this.sd.year,this.sd.month-1, this.sd.day+1)).toISOString().substring(0,10);
        },
        edate: function() {
          return (new Date(this.ed.year,this.ed.month-1, this.ed.day+1)).toISOString().substring(0,10);
        },
        yearsList: function(){
          const start_year = this.$store.state.licenseType.startYear;
          let t = [{text: this.lang.year, value: -1}];
          for(let i=0;i<100;i++){
            if(parseInt(start_year) + i <= (new Date()).getFullYear()){
              t.push({text: start_year + i, value: start_year+i})
            }
            
          }
          return t
        },
        daysList: function(){
          return [
            {text: this.lang.day, value: -1},
            {text: 1, value: 1},
            {text: 2, value: 2},
            {text: 3, value: 3},
            {text: 4, value: 4},
            {text: 5, value: 5},
            {text: 6, value: 6},
            {text: 7, value: 7},
            {text: 8, value: 8},
            {text: 9, value: 9},
            {text: 10, value: 10},
            {text: 11, value: 11},
            {text: 12, value: 12},
            {text: 13, value: 13},
            {text: 14, value: 14},
            {text: 15, value: 15},
            {text: 16, value: 16},
            {text: 17, value: 17},
            {text: 18, value: 18},
            {text: 19, value: 19},
            {text: 20, value: 20},
            {text: 21, value: 21},
            {text: 22, value: 22},
            {text: 23, value: 23},
            {text: 24, value: 24},
            {text: 25, value: 25},
            {text: 26, value: 26},
            {text: 27, value: 27},
            {text: 28, value: 28},
            {text: 29, value: 29},
            {text: 30, value: 30},
            {text: 31, value: 31},
          ]
        },
        monthsList: function(){
          return [
            {text: this.lang.chose_month, value: -1},
            {text: 1, value: 1},
            {text: 2, value: 2},
            {text: 3, value: 3},
            {text: 4, value: 4},
            {text: 5, value: 5},
            {text: 6, value: 6},
            {text: 7, value: 7},
            {text: 8, value: 8},
            {text: 9, value: 9},
            {text: 10, value: 10},
            {text: 11, value: 11},
            {text: 12, value: 12},
          ]
        },
        tabs: function() {
          const menu = this.$store.getters['state'].menu;
            const license = this.$store.getters['state'].licenseType.activemenu;
            const alicense = this.$store.getters['state'].licenseType
            let currentMenu = menu[license];
            if(!license){
              return false;
            }
            let cMenu = {main: {},sub:[]};
            Object.values(currentMenu).forEach(value =>{
            const cM = localStorage.getItem('currentMenu');
            if(value.main.index == cM){
                cMenu = value.sub;
            }else if(cM == null && value.main.index == 1){
                cMenu = value.sub;
            }
            });
        
            let t = []
            Object.values(cMenu).forEach(value =>{
            
                if(this.$route.path == value.href){
                    value.class = "mytab";
                }else{
                    value.class = "mytab4";
                }
                if(this.lang.langname == 'ar'){
                    value.name = value.arname
                }else{
                    value.name = value.enname
                }
                for(let i =0; i< value.groupid.length;i++){
                    if(value.groupid[i] == this.$store.getters['state'].group_id){
                    if(alicense[value.perms[0]] || value.perms[0] == 'any')
                        t.push(value)
                    }
                }
            
            })
        
            return t;
        },
       
    },
    methods: {

        getCustomers(){
            const post = new FormData(); 
            post.append("type","getCustomerSalesDashboard");
            post.append('defBranch',localStorage.getItem('defBranch'));
            post.append('financeYear',localStorage.getItem('financeYear'));
            post.append("auth",this.$cookies.get(this.$store.state.COOKIEPhase));
            post.append("data[id]",0)

    
            axios.post(
                this.$SAMCOTEC.r_path,post
            ).then((response) => {
                if(response && response.data){
                    this.statuses = response.data.results.data;
                }
            })

        },

        
    },
    created(){

        this.getCustomers()

    }
}
</script>

<style>
.showDash{
    margin-top:50px;
    display:flex;
    flex-direction:row;
    justify-content:center;
    items-align:center;
    justify-items:center;
}
.dashContainer{
    width: 250px;
    margin-inline-end: 5px;
    border:1px solid #000;
    border-raduis: 5px;
    padding:0px;
}
.dashTitle{
    background:blue;
    color:#fff;
    padding:5px;
    text-align:center;
}
.containerText{
    margin:5px 5px 5px 0px;
}
.containerRow{
    margin-top:5px;
    display:flex;
    flex-direction:row;
    justify-content:center;
    items-align:center;
}
.rowTitle{
    width: 110px;
    text-align:center;
    padding:2px;
    background: black;
    color: #fff;
}
.rowText{
    width: 130px;
    text-align:center;
    padding:2px;
}
</style>